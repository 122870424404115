import React from 'react';
import { Route, Routes } from "react-router-dom";
import Preloader from './components/global/Preloader';
import HomeLanding from './pages/home/HomeLanding';
import Style2 from './pages/home/Style2';
import Style3 from './pages/home/Style3';
import NotFound from './pages/404/NotFound';
import ContactUs from './pages/contactUs/ContactUs';
import About from './pages/about/About';
import MyAccount from './pages/myAccount/MyAccount';
import Checkout from './pages/checkout/Checkout';
import Cart from './pages/cart/Cart';
import BlogMasonary from './pages/blog/BlogMasonary';
import FullWidth from './pages/shop/FullWidth';
import LeftSidebar from './pages/shop/LeftSidebar';
import RightSidebar from './pages/shop/RightSidebar';
import Blog from './pages/blog/Blog';
import BlogSingle from './pages/blog/BlogSingle';
import SingleVerticalThumbnail from './pages/shop/SingleVerticalThumbnail';
import ShopSliderImages from './pages/shop/ShopSliderImages';
import { useGlobalContext } from './providers/GlobalProvider';
import Category from './pages/category/Category';
import ThankYouPage from './pages/thank-you-page/ThankYouPage';
import CartProvider from './providers/CartProvider';
import OrderProvider from './providers/OrderProvider';
import ProductsProvider from './providers/ProductsProvider';
import HowToOrder from './pages/how-to-order/HowToOrder';
import TermsOfShipping from './pages/terms-of-shipping/TermsOfShipping';
import PaymentMethods from './pages/payment-methods/PaymentMethods';
import MoneyBackConditions from './pages/money-back-conditions/MoneyBackConditions';
import StatsProvider from './providers/StatsProvider';

const App = () => {
    const { layouLoaded } = useGlobalContext()

    return (
        <>
            {
                layouLoaded ? (
                    <>
                        <StatsProvider>
                            <ProductsProvider>
                                <CartProvider>
                                    <OrderProvider>
                                        <Routes>
                                            <Route path='/' element={<HomeLanding />} exact />
                                            <Route path='/home' element={<HomeLanding />} exact />
                                            <Route path='/home-style-2' element={<Style2 />} exact />
                                            <Route path='/home-style-3' element={<Style3 />} exact />
                                            <Route path='/404' element={<NotFound />} exact />
                                            <Route path='/contact' element={<ContactUs />} exact />
                                            <Route path='/about' element={<About />} exact />
                                            <Route path='/my-account' element={<MyAccount />} exact />
                                            <Route path='/checkout' element={<Checkout />} exact />
                                            <Route path='/cart' element={<Cart />} exact />
                                            <Route path='/test' element={<Category />} exact />
                                            <Route path='/shop-full-width' element={<FullWidth />} exact />
                                            <Route path='/shop-left-sidebar' element={<LeftSidebar />} exact />
                                            <Route path='/shop-right-sidebar' element={<RightSidebar />} exact />
                                            <Route path='/blog' element={<Blog />} exact />
                                            <Route path='/blog-masonary' element={<BlogMasonary />} exact />
                                            <Route path='/blog-single' element={<BlogSingle />} exact />
                                            <Route path='/single-vertical-thumbnail' element={<SingleVerticalThumbnail />} exact />
                                            <Route path='/single-slider-images' element={<ShopSliderImages />} exact />
                                            <Route path='/category' element={<FullWidth />} exact />
                                            <Route path='/product' element={<ShopSliderImages />} exact />
                                            <Route path='/thank-you-page' element={<ThankYouPage />} exact />
                                            <Route path='/how-to-order' element={<HowToOrder />} exact />
                                            <Route path='/terms-of-shipping' element={<TermsOfShipping />} exact />
                                            <Route path='/payment-methods' element={<PaymentMethods />} exact />
                                            <Route path='/money-back-conditions' element={<MoneyBackConditions />} exact />
                                            <Route path='*' element={<NotFound />} exact />
                                        </Routes>
                                    </OrderProvider>
                                </CartProvider>
                            </ProductsProvider>
                        </StatsProvider>
                    </>
                ) : (<Preloader />)
            }
        </>
    );
};

export default App;