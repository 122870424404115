import React from 'react';
import { Link } from "react-router-dom";



const Logo = () => {
    return (
        <>
            <Link className="logo-middle" to="/">
                <img src={process.env.PUBLIC_URL + '/assets/images/logo3.png'} alt="" />
            </Link>
        </>
    );
};

export default Logo;