import axios from 'axios';
import React, { createContext, useContext } from 'react';

const ApiContext = createContext()

const ApiProvider = ({ children }) => {

    const PUBLIC_URL = "https://api.gunesh.uz";
    // const PUBLIC_URL = "http://127.0.0.1:8081";

    const getLayoutRequest = (completeCallback, errorCallbcack) => {
        axios.get(PUBLIC_URL + '/v1/layout/get')
            .then((response) => {
                const data = response.data;
                if (data.has_error === true) {
                    errorCallbcack("has error");
                } else {
                    completeCallback(data);
                }
            }).catch((error) => {
                errorCallbcack(error);
            });
    }

    const getProductRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/product/get', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    const getProductsRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/products/get', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    const createOrderRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/orders/create', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    const addLeadRequest = (data, completeCallback, errorCallbcack) => {
        axios.post(PUBLIC_URL + '/v1/leads/add', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    const addStatRequest = (data) => {
        axios.post(PUBLIC_URL + '/v1/admin/stats/add', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).catch((error) => {
            console.log(error)
        });
    }

    const testOrderRequest = (data, completeCallback, errorCallbcack) => {
        console.log(data)
        axios.post(PUBLIC_URL + '/v1/mail/test', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            const data = response.data;
            if (data.has_error === true) {
                errorCallbcack("has error");
            } else {
                completeCallback(data);
            }
        }).catch((error) => {
            errorCallbcack(error);
        });
    }

    const contextValue = {
        testOrderRequest,
        createOrderRequest,
        getProductsRequest,
        getProductRequest,
        getLayoutRequest,
        addLeadRequest,
        addStatRequest
    }
    return (
        <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
    );
};

export const useApiContext = () => useContext(ApiContext)

export default ApiProvider;
