import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import HeaderRight from './HeaderRight';
import HeaderLeft from './HeaderLeft';
import Navbar from './Navbar';
import { useGlobalContext } from '../../providers/GlobalProvider';
import styled from 'styled-components';


const Header = () => {
    const { handleOverlayStatus, handelMobileNavStatus, showMobileNav, showSideInfo, showMiniCart, showOrderByPhone, setNavbarHeight } = useGlobalContext();

    const headerWrapperRef = useRef(null)

    useEffect(() => {
        setNavbarHeight(headerWrapperRef.current.clientHeight)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <div
            className={"page-wrapper " + (showOrderByPhone || showSideInfo || showMobileNav || showMiniCart ? 'active-body-overlay' : '')}>
            <div
                className="body-overlay"
                onClick={handleOverlayStatus}
            />
        </div>

        {/* Start header */}
        <header id="header" className="site-header header-style-1">
            <HeaderWrapper ref={headerWrapperRef}>

                <nav className="navigation navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="open-btn" onClick={handelMobileNavStatus}>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <Link className="mobile-only navbar-brand" to="/">
                                <img src={process.env.PUBLIC_URL + "/assets/images/logo3.png"} alt="" />
                            </Link>

                        </div>

                        <HeaderLeft />

                        <Navbar />

                        <HeaderRight />

                    </div>
                    {/* end of container */}
                </nav>
            </HeaderWrapper>
        </header>
        {/* end of header */}
    </>;
};

export default Header;

const HeaderWrapper = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 99;
`