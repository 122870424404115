import React, { createContext, useContext } from 'react'
import { useApiContext } from './ApiProvider'

const StatsContext = createContext()

const StatsProvider = ({ children }) => {

    const { addStatRequest } = useApiContext()

    const callCustomEvent = () => {
        if (window.fbq !== undefined) {
            window.fbq('trackCustom', 'HomePageView', { promotion: 'share_discount_10%' });
        }
    }


    const callViewContent = (data) => {
        if (window.fbq !== undefined) {
            window.fbq('track', 'ViewContent', { currency: 'USD', value: parseInt(data.price) / 10000 });
        }

        addStatRequest({ productId: data.id, productName: data.title, actionType: "ViewContent" })
    }

    const callAddToCart = (data) => {
        if (window.fbq !== undefined) {
            window.fbq('track', 'AddToCart', { currency: 'USD', value: parseInt(data.price) / 10000, });
        }

        addStatRequest({ productId: data.id, productName: data.title, actionType: "AddToCart" })
    }

    const callAddLead = () => {
        if (window.fbq !== undefined) {
            window.fbq('track', 'Lead');
        }
    }

    const callPurchase = (price) => {
        if (window.fbq !== undefined) {
            window.fbq('track', 'Purchase', { currency: 'USD', value: price });
        }
    }

    const contextValue = {
        callCustomEvent,
        callViewContent,
        callAddToCart,
        callAddLead,
        callPurchase
    }

    return (
        <StatsContext.Provider value={contextValue}>
            {children}
        </StatsContext.Provider>
    )
}

export const useStatsContext = () => useContext(StatsContext)

export default StatsProvider